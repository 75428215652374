import ajax from 'ajax';

const UpdateProgress = (courseId, moduleID) => {
    console.log(courseId, moduleID)

    ajax({
        url: '/actions/phh/phh',
        type: 'POST',
        data: {
            courseId,
            moduleID 
        }
    }).then((res) => { 
        console.log(res)
    }).catch((err) => {
        console.log(err)
    });
}

const courseProgress = (el) => {
    const courseId = el.dataset.courseId;
    const moduleId = el.dataset.moduleId;

    el.addEventListener('click', UpdateProgress(courseId, moduleId));
}

export default courseProgress;