async function fetchVimeoThumbnail(videoId) {
    try {
      const response = await fetch(
        `https://vimeo.com/api/v2/video/${videoId}.json`,
        {
          method: "GET",
          mode: "cors", // Set mode to 'cors' for cross-origin requests
          headers: {
            "Content-Type": "application/json",
            // Add any other headers if needed
          },
        }
      );
  
      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
  
      const data = await response.json();
      return data[0].thumbnail_large;
    } catch (error) {
      console.error(error);
    }
  }
  
  const fullScreenVideo = async (el) => {
    const videoThumbnail = el.querySelector("#video-thumbnail");
    const videoPopup = el.querySelector("#video-popup");
    const closePopup = el.querySelector("#close-popup");
    const backdrop = el.querySelector("#video-backdrop");
    // if (!backdrop) return;
  
    // Fetch and set thumbnail
    // const videoId = el.dataset.video;
    // fetchVimeoThumbnail(videoId).then((url) => {
    //   videoThumbnail.style.backgroundImage = `url(${url})`;
    // });
    try {
      backdrop.addEventListener("click", function () {
        videoPopup.classList.add("hidden");
      });
  
      closePopup.addEventListener("click", function () {
        videoPopup.classList.add("hidden");
      });
  
      videoThumbnail.addEventListener("click", function () {
        videoPopup.classList.remove("hidden");
      });
    } catch (error) {
      console.warn("No video popup found - skipping");
    }
  };
  
  export default fullScreenVideo;
  