window.addEventListener("load", function() {
    const elements = document.querySelectorAll("#video-thumbnail");
    var moduleButton = document.querySelector(".complete-button");

    elements.forEach((el) => {
        el.addEventListener("click", function () {
            var currentVideoPopup = el.nextElementSibling;
            var currentVideoIFrame = currentVideoPopup.querySelector("iframe");
            var closePopup = currentVideoPopup.querySelector("#close-popup");

            var player = new Vimeo.Player(currentVideoIFrame);
            var videoDuration;
            var intervalId;

            function checkRemainingTime() {
                player.getCurrentTime().then(function(currentTime) {
                    var remainingTime = videoDuration - currentTime;

                    if (remainingTime < 30 && remainingTime >= 0.1) {
                        var checkInterval = setInterval(function() {
                            player.getCurrentTime().then(function(currentTime) {
                                remainingTime = videoDuration - currentTime;
                                if (remainingTime < 5) {
                                    clearInterval(checkInterval);
                                    moduleButton.click();
                                }
                            }).catch(function(error) {
                                console.error("Error getting current time: ", error);
                            });
                        }, 1000);
                    } else if (remainingTime < 30) {
                        console.log("Less than 30 seconds remaining");
                    }
                }).catch(function(error) {
                    console.error("Error getting current time: ", error);
                });
            }

            player.on('loaded', function() {
                player.getDuration().then(function(duration) {
                    videoDuration = duration;
                }).catch(function(error) {
                    console.error(error);
                });
            });

            player.on('play', function() {
                intervalId = setInterval(checkRemainingTime, 9000);
                console.log("Video started playing");
            });

            player.on('pause', function() {
                clearInterval(intervalId);
                console.log("Video paused");
            });

            player.on('ended', function() {
                clearInterval(intervalId);
                console.log("Video ended");
            });

            closePopup.addEventListener("click", function () {
                clearInterval(intervalId);
                console.log("Popup closed");
            });
        });
    });
});
