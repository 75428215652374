import eventBus from "./eventBus";

let options = {
  rootMargin: "-150px",
  threshold: 0.2,
};

function inview() {
  let all = [];

  let observer = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      const item = all.find((item) => item.el === entry.target);
      all.find((item) => item.el === entry.target);
      if (entry.isIntersecting) {
        item?.enter();
      } else {
        item?.leave();
      }
    });
  }, options);

  function set(el, callback) {
    observer.observe(el);
    all.push({
      el,
      ...callback,
    });
  }

  eventBus.on("page:enter", () => {
    observer.disconnect();
    all = [];
  });

  return {
    set,
  };
}

export default inview();
