function ScrollBar(el) {
    const content = el;
    const scrollBar = document.querySelector('#scrollbar');
    if (!scrollBar) return;
    const scroller = scrollBar.querySelector('#scroller');
    const scrollbarHeight = scrollBar.scrollHeight;
    const scrollHeight = content.scrollHeight;


    scrollbar.style.top = content.offsetTop + 50 + 'px';

    function update()  {
        let scrollTop = content.scrollTop;
        let clientHeight = content.clientHeight;
    
        // Calculate the height and position of the scroller
        const scrollerHeight = (clientHeight / scrollHeight) * scrollbarHeight;
        const scrollerPosition = (scrollTop / (scrollHeight - clientHeight)) * (scrollbarHeight - scrollerHeight);
    
        // Set the height and position of the scroller
        scroller.style.height = scrollerHeight + 'px';
        scroller.style.top = scrollerPosition + 'px';        
    }
    update()
    content.addEventListener('scroll', update);  
}

export default ScrollBar;