import Flickity from "flickity";

function Slider(el) {
  el.classList.remove("hidden");
  const options = el.dataset?.options ? JSON.parse(el.dataset.options) : {};
  
  const flk = new Flickity(el, options);

  function nextSlide() {
    flk.next();
  }

  function prevSlide() {
    flk.previous();
  }

  document
    .querySelectorAll(options?.nextButton)
    .forEach((el) => el.addEventListener("click", nextSlide));

  document
    .querySelectorAll(options?.prevButton)
    .forEach((el) => el.addEventListener("click", prevSlide));

  document.addEventListener("lazyloaded", (e) => {
    flk.resize();
  });

  return () => {
    flk.destroy();
  };
}

export default Slider;