const seeMore = (el) => {
    const text = el.dataset.text;
    const characterLimit = parseInt(el.dataset.limit);
    const textContainer = el.querySelector("#text-container");
    const readMore = el.querySelector("#read-more");

    let isTruncated = true;

    readMore.addEventListener("click", function() {
        if (isTruncated) {
            textContainer.innerHTML = text;
            readMore.innerHTML = "[Read less]";
        } else {
            textContainer.innerHTML = text.slice(0, characterLimit || 10);
            readMore.innerHTML = "[Read more]";
        }
        isTruncated = !isTruncated;
    });
};

export default seeMore;