import inview from "./utils/inview";
import eventBus from "./utils/eventBus";
import { gsap } from "gsap";

const buttonAnim = (el) => {
  const delay = +(el.dataset?.delay ?? 0.2);
  el.style.opacity = 0;

  inview.set(el, {
    enter() {
      setTimeout(() => {
        el.style.animation = `0.7s button-up ${delay}s ease-out forwards`;
      }, delay);
    },
    leave() {
      el.style.opacity = 0;
    },
  });
};

const headingAnim = (el) => {
  const delay = +(el.dataset?.delay ?? 0.2);
  el.style.opacity = 0;
  let animationTriggered = false; // Flag to track animation state

  inview.set(el, {
    enter() {
      if (!animationTriggered) {
        animationTriggered = true; // Set flag to true once animation is triggered

        setTimeout(() => {
          el.style.animation = `1s heading-fade-in ${delay}s ease-out forwards`;
          animate(); // Call the animate function to handle text animation
        }, delay);
      }
    },
    leave() {
      if (!animationTriggered) {
        el.style.opacity = 0; // Only fade out if animation hasn't been triggered
      }
    },
  });

  function animate() {
    if (!animationTriggered) return; // Don't run animation if it has already been triggered

    // Add your text animation logic using GSAP here
    gsap.from(el, { opacity: 0, y: 20, duration: 1 });

    // You can also add any other animations you need
  }
};

export { buttonAnim, headingAnim };
export default function TextAnim(el) {
  const textEl = el.querySelector("[data-text]");
  const text = textEl.innerText;
  const delay = +(el.dataset?.delay ?? 0);
  let animationTriggered = false; // Flag to track animation state

  inview.set(el, {
    enter() {
      if (!animationTriggered) {
        animationTriggered = true; // Set flag to true once animation is triggered

        setTimeout(() => {
          el.style.transition = "opacity 0.5s ease-out";
          el.style.opacity = 1;
          animate();
        }, delay);
      }
    },
    leave() {
      if (!animationTriggered) {
        el.style.opacity = 0; // Only fade out if animation hasn't been triggered
      }
    },
  });

  function animate() {
    if (!animationTriggered) return; // Don't run animation if it has already been triggered

    let delay = 0;
    let colorDelay = delay / 1000;

    try {
      textEl.innerHTML = text
        .split(" ")
        .map((word, i) => {
          const newWord = word
            .split("")
            .map((letter) => {
              delay += 0.02;
              return `<span class="ta-letter" style="animation-delay: ${delay}s">${letter}</span>`;
            })
            .join("");

          let color = [
            "award",
            "winning,",
            "creative,",
            "digital",
            // ... (other color words)
          ].includes(word.trim());

          if (color) {
            colorDelay += 0.1;
          }

          return `<span class='ta-word ${
            color ? " ta-color " : ""
          }' style='animation-delay: ${colorDelay}s'>${newWord}</span>`;
        })
        .join("");

      eventBus.emit("scrolltrigger:refresh");
    } catch (error) {}
  }

  return () => {};
}
