import { gsap } from "gsap/all";

function setVideoSize(el) {
  let ratio = 1;
  const vid = { w: 1920, h: 1080, ratio: 1920 / 1080 };
  const cont = {
    w: el.offsetWidth,
    h: el.offsetHeight,
    ratio: el.offsetWidth / el.offsetHeight,
  };

  if (vid.ratio > cont.ratio) {
    ratio = cont.h / vid.h;
  } else {
    ratio = cont.w / vid.w;
  }

  gsap.set(el.firstElementChild, {
    scale: ratio,
  });
}

export default function BackgroundVideo(el) {
  if (typeof Plyr === "undefined") {
    console.error("Plyr is not defined.");
    return;
  }

  const video = el.querySelector("[data-video]");
  let hasStarted = false;
  el.style.opacity = 0;

  const plyr = new Plyr(video, {
    debug: false,
    autoplay: true,
    muted: true,
    volume: 0,
    controls: [],
    loop: {active: true},
  });

  plyr.on("ready", () => {
    plyr.volume = 0;
    setVideoSize(el);
  });
  plyr.on("playing", () => {
    if (!hasStarted) {
      gsap.fromTo(el, { opacity: 0 }, { opacity: 1, duration: 0.5 });
    }
    hasStarted = true;
  });

  window.addEventListener("resize", () => setVideoSize(el));
}