import "core-js/stable";
import "regenerator-runtime/runtime";
import "./app.scss";

import Alpine from "alpinejs";
import ScrollBar from "./js/scrollBar";
import Slider from "./js/slider";
import BackgroundVideo from "./js/video";
import TextAnim, {buttonAnim, headingAnim} from "./js/TextAnim";
import Testimonial from "./js/testimonials";
import toggleNav from "./js/toggleNav";
import seeMore from "./js/seeMore";
import fullScreenVideo from "./js/fullscreenVideo";
import "./js/videoEventListener";
import courseProgress from "./js/updateCourseProgress";
import './js/orphans'


window.Alpine = Alpine;

document.querySelectorAll("[data-action='ScrollBar']").forEach(ScrollBar);

var headings = document.querySelectorAll(".accordion .left .accord-heading");
var contents = document.querySelectorAll(".accordion .right .accord-content");

headings.forEach(function (heading, index) {
  heading.addEventListener("click", function () {
    // Hide all contents
    contents.forEach(function (content) {
      content.classList.remove("active");
    });
    
    // Show the clicked content
    contents[index].classList.add("active");
    
    // Toggle active class on headings
    headings.forEach(function (h) {
      h.classList.remove("active");
    });
    heading.classList.add("active");
  });
});

function action(attr, func) {
  if (attr.indexOf(".") !== -1 || attr.indexOf("#") !== -1) {
    document.querySelectorAll(attr).forEach((el) => func(el));
  } else {
    document
    .querySelectorAll(`[data-action="${attr}"]`)
    .forEach((el) => func(el));
  }
}
action("BackgroundVideo", BackgroundVideo);
action("Slider", Slider);
action("TextAnim", TextAnim);
action("buttonUp", buttonAnim);
action("headingFadeIn", headingAnim);
action('toggleNav', toggleNav);
action('readMore', seeMore);
action('fullScreenVideo', fullScreenVideo);
action('courseProgress', courseProgress);

const InitImageLists = () => {
  const ids = ['testimonial'];
  document.querySelectorAll('[data-image-list]').forEach((el) => {
    ids.push(el.id);
  });
  Testimonial(ids);
}
InitImageLists();


Alpine.start();