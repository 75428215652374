import Splide from "@splidejs/splide";
import {debounce} from 'lodash'

const Testimonial = (idList) => {
    idList.forEach((id) => {
        const slideEl = document.getElementById(id);
        if (!slideEl) return;
        const splideItems = slideEl.querySelectorAll(".splide__slide");
        const maximumSlides = Math.min(splideItems.length, 3);

        if(slideEl) {
            const splide = new Splide(slideEl, {
                type: "loop",
                perPage: window.innerWidth >= 768 ? maximumSlides:1,
                perMove: 1,
                autoplay: true,
                speed: 1000,
                interval: 5000,
                direction: window.innerWidth >= 768 ? "ttb" : "ltr",
                height: window.innerWidth >= 768 ? "400px":'150px',
                pagination: true,
            }).mount();
            splide.on("move updated", function (newIndex) {
                Alpine.store(id).currentItem = newIndex;
            });
            Alpine.store(id, {
                currentItem: splide.index,
            });

            const updateOptions = debounce(() => {
                splide.options = {
                  ...splide.options,
                  perPage: window.innerWidth >= 768 ? maximumSlides : 1,
                  direction: window.innerWidth >= 768 ? "ttb" : "ltr",
                  height: window.innerWidth >= 768 ? "400px" : "150px",
                };
                Alpine.store(id).currentItem = splide.index;
              }, 200);
            window.addEventListener('resize', updateOptions);
        }
    });
}

export default Testimonial;